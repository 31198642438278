"use client";
import PlusMinusRow from "@/components/ui/PlusMinusRow";
import Popup from "@/components/ui/Popup";
import { toast } from "@/hooks/use-toast";
import { useLocale, useTranslations } from "next-intl";
import React from "react";
import LocalizedArrowIcon from "./LocalizedArrowIcon";

function CommonSearchVisitors({
	adults,
	setAdults,
	childrens,
	setChildren,
	hideVisitors,
}: {
	adults: number;
	setAdults: (adults: number) => void;
	childrens: number;
	setChildren: (children: number) => void;
	hideVisitors?: boolean;
}) {
	const [isPopupOpen, setIsPopupOpen] = React.useState(false);
	const t = useTranslations();
	const locale = useLocale();
	return (
		<div className={`${!hideVisitors ? "py-[2px]" : ""} relative`}>
			<button
				disabled={isPopupOpen}
				onClick={() => setIsPopupOpen(!isPopupOpen)}
				className={` cursor-pointer items-start  flex flex-col  gap-[8px]`}
			>
				{/* <p className=' text-Gray text-[11.75px] font-medium'>{t('visitors')}</p> */}
				{/* <SearchCardTitle title={t('visitors')} /> */}
				<p className=" text-[11px] font-normal text-DarkGrey">
					{t("visitors")}
				</p>
				<div className=" flex items-center gap-[10px]">
					<h1 className="  font-bold ">
						{adults} {t("adults")} {hideVisitors ? "|" : "."} {childrens}{" "}
						{t("children")}
					</h1>
					{hideVisitors && <LocalizedArrowIcon locale={locale} />}
				</div>
				{!hideVisitors && (
					<p className=" font-medium text-[9.75px]">
						{childrens + adults} {t("visitors")}
					</p>
				)}
			</button>

			<Popup
				className="  top-[105px] -start-[38px] py-7 px-10 flex flex-col gap-[15px]"
				isOpen={isPopupOpen}
				setIsOpen={setIsPopupOpen}
			>
				<PlusMinusRow
					text="adults"
					hint={"more than 13 years"}
					setValue={(value) => {
						if (value < 1) {
							toast({
								title: t("minimumAdultsError"),
								variant: "destructive",
							});
							return;
						}
						setAdults(value);
					}}
					value={adults}
				/>
				<PlusMinusRow
					text="children"
					setValue={setChildren}
					hint="between 2 to 12 years"
					value={childrens}
				/>
			</Popup>
		</div>
	);
}

export default CommonSearchVisitors;
