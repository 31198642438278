export const API_BASE_URL = "https://api.aroovia.com/api";
export const TOKEN = "token";
export const NEXT_LOCALE = "NEXT_LOCALE";
export const namePrefix = "alsdnaljsdnjsbfkbskhfb";
export const IMG_BASE_URL = "https://api.aroovia.com/api/uploads?path=";
export const IS_AUTH_POPUP_FINISHED_DEVEOPEMENT = false;
export const endpoints = {
	property_types: "/property/property-types",
	categoriesList: "/categories/list",
	search: "/search/type",
	websiteInfo: "/website-info",
	faq: "/faq/list",
	amenities: "/amenity/list",
	random: "/search/random",
	nationalitiesList: "/auth/user/nationalities",
	payoutList: "/vendor/payout/list",
	cancellationPolicyList: "/cancellation-policy/list",
	vendorBankCreate: "/vendor/bank/create",
	bankList: "/vendor/bank/list",
	property: "/property",
	removeImage: "/property/remove-image",
	citiesList: "/city/list",
	districtsList: "/district/list",
	youMayLike: "/search/you-may-like",
	createProperty: "/property/create",
	addToWithList: "/wishlist/add-remove",
	updateProperty: "/property/update",
	payoutRequest: "/vendor/payout/request",
	guestPrefer: "/search/guest-prefer",
	updateProfile: "/auth/user/update",
	login: "/auth/user/login",
	supportCenter: "/kamel/list",
	reservationInfo: "/kamel",
	notificationList: "/notification/list",
	supportTickets: "/support-tickets/create",
	guestCancelCheck: "/kamel/guest-cancel-check",
	wishlist: "/wishlist/list",
	validatePayment: "/kamel/validate-payment",
	guestCancel: "/kamel/guest-cancel",
	checkAvailability: "/reservation/check-availability",
	reservations: "/kamel/list",
	rejectOrder: "/kamel/reject",
	priceCharts: "/search/filter/price/charts",
	acceptOrder: "/kamel/accept",
	checkInCheck: "/kamel/check-in",
	checkOutCheck: "/kamel/check-out",
	hostList: "/kamel/host-list",
	harm: "/support-settlements/list-reasons",
	filterSearch: "/search/filter-distinations",
	copoun: "/copoun/check",
};

export const ZINDEX = {
	profileNavbar: 20,
	dropDownMenu: 40,
	likeHeart: 15,
	popup: 25,
	login: 21,
	stepper: 2,
};

export const SA_BOUNDS = {
	north: 32.154284,
	south: 16.379528,
	west: 34.557327,
	east: 55.666659,
};
export const charSplit = 32;
