import { buttonVariants } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { useLocale } from "next-intl";
import React from "react";
import { DayPicker } from "react-day-picker";

// Import necessary locales from date-fns
import { ar, enUS } from "date-fns/locale";
import { ChevronLeft, ChevronRight } from "lucide-react";

export type CalendarProps = React.ComponentProps<typeof DayPicker>;
function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  selectedDate,
  ...props
}: CalendarProps & { selectedDate?: { from?: Date; to?: Date } }) {
  const locale = useLocale();

  const getLocale = () => {
    switch (locale) {
      case "ar":
        return {
          ...ar,
          weekdays: [
            "الأحد", // Sunday
            "الاثنين", // Monday
            "الثلاثاء", // Tuesday
            "الأربعاء", // Wednesday
            "الخميس", // Thursday
            "الجمعة", // Friday
            "السبت", // Saturday
          ],
          weekdaysShort: [
            "الأحد", // Sun
            "الاثنين", // Mon
            "الثلاثاء", // Tue
            "الأربعاء", // Wed
            "الخميس", // Thu
            "الجمعة", // Fri
            "السبت", // Sat
          ],
        };
      case "en":
      default:
        return enUS;
    }
  };

  // Determine direction based on locale
  const direction = locale === "ar" ? "rtl" : "ltr";
  return (
    <DayPicker
      locale={getLocale()}
      formatters={{
        formatWeekdayName: (date) =>
          date.toLocaleDateString(locale, {
            weekday: locale == "ar" ? "long" : "short",
          }),
      }}
      showOutsideDays={showOutsideDays}
      className={cn("md:p-3", className)}
      dir={direction}
      lang={locale}
      fromDate={new Date()}
      classNames={{
        root: "relative",
        months:
          "flex flex-col lg:flex-row w-full space-y-4 gap-[0px] lg:space-x-4 lg:space-y-0",
        month: "space-y-4",
        caption: "flex justify-center pt-1 relative items-center",
        caption_label: "text-sm font-medium",
        nav: "space-x-1 flex items-center",
        nav_button: cn(
          buttonVariants({ variant: "outline" }),
          "!h-9 !w-9  p-0 opacity-80 hover:opacity-100 hover:rounded-full bg-transparent"
        ),
        nav_button_previous: "absolute start-1 ",
        nav_button_next: "absolute  end-1",
        table: "w-full border-collapse space-y-2",
        head_row:
          "flex md:gap-y-2  w-full md:gap-x-[13px] gap-x-[9px] justify-center",
        head_cell:
          "text-neutral-500 flex !font-semibold  justify-center !rounded-full px-4 text-start   w-8 text-[0.8rem] dark:text-neutral-400",
        row: "flex gap-y-2 !px-[51px] md:gap-x-[7px] gap-x-[2=1px] w-full mt-2",
        cell: cn(
          "relative p-0 !text-start text-sm  px-1 focus-within:relative focus-within:z-45 ",
          props.mode === "range"
            ? "[&:has(>.day-range-end)]:rounded-r-none [&:has(>.day-range-start)]:rounded-l-none first:[&:has([aria-selected])]:rounded-l-none last:[&:has([aria-selected])]:rounded-r-none"
            : "[&:has([aria-selected])]:rounded-none"
        ),
        day: cn(
          buttonVariants({ variant: "ghost" }),
          "h-8 w-8 md:p-2 font-normal text-end aria-selected:opacity-100"
        ),
        day_range_start: `
            day-range-start
            !rounded-full 
            !bg-Red 
            !text-white 
            relative 
            z-[2]
            w-8 
            h-8
            after:absolute
            after:top-0
            after:h-8
            after:end-0
            after:start-1/2
            ${selectedDate?.to ? "after:bg-[rgba(239,63,36,0.4)]" : "after:bg-transparent after:h-0"} // not here
            after:w-[24px]
            md:after:w-[31px]

            after:-z-[1]
          `,
        day_range_end: `
            day-range-end
            !rounded-full 
            !bg-Red 
            !text-white 
            relative 
            z-[2]
            w-8 
            h-8
            before:absolute
            before:top-0
            before:h-8
            before:bg-[rgba(239,63,36,0.4)]
            ${selectedDate?.to ? "before:bg-[rgba(239,63,36,0.4)]" : "before:bg-transparent before:h-0"} // not here
            
            before:start-0
            before:end-1/2
            before:-z-[1]
          `,
        day_selected: `
            text-black
            relative
            z-[2]
          `,
        day_today:
          "bg-neutral-100 rounded-full text-neutral-900 dark:bg-neutral-800 dark:text-neutral-50",
        day_outside:
          "day-outside text-neutral-500 opacity-50 aria-selected:bg-neutral-100/50 aria-selected:text-neutral-500 aria-selected:opacity-30 dark:text-neutral-400 dark:aria-selected:bg-neutral-800/50 dark:aria-selected:text-neutral-400",
        day_disabled: "text-neutral-500 opacity-50 dark:text-neutral-400",
        day_range_middle: `
            day-range-middle
            relative
            before:absolute
            before:inset-0
            before:bg-[rgba(239,63,36,0.4)] // not here
            before:-z-[1]
            md:before:w-[47px]
            before:w-[40px]
            before:h-8
            !text-black
          `,
        day_hidden: "invisible",
        ...classNames,
      }}
      components={{
        IconLeft: () => (
          <div
            className={`html-content      border-[2px] bg-Red rounded-full p-2 text-[40px]`}
            // lg:relative
          >
            <ChevronLeft size={75} className={`font-extrabold text-white`} />
          </div>
        ),
        IconRight: () => (
          <div
            className={`html-content ${locale == "en" ? "end-4" : "start-4"}  border-[2px] bg-Red rounded-full p-2 text-[40px]`}
            // lg:relative
          >
            <ChevronRight size={75} className={`font-extrabold text-white`} />{" "}
          </div>
        ),
      }}
      {...props}
    />
  );
}

Calendar.displayName = "Calendar";

export { Calendar };
