import { useTranslations } from "next-intl";
import React from "react";
import { IoIosAddCircleOutline } from "react-icons/io";
import { CiCircleMinus } from "react-icons/ci";
function PlusMinusRow({
	text,
	value,
	setValue,
	hint,
}: {
	hint?: string;
	text: string;
	value: number;
	setValue: (value: number) => void;
}) {
	const t = useTranslations();
	return (
		<div className=" flex items-center  flex-row-reverse gap-[25px]">
			<div className=" flex   font-extrabold text-[15px] items-center gap-[8px]">
				<button
					className="  "
					onClick={() => setValue(value + 1)}
				>
					<IoIosAddCircleOutline size={25} />
				</button>
				{value}
				<button
					className={!value ? "text-gray-300" : ""}
					disabled={!value}
					onClick={() => setValue(value - 1)}
				>
					<CiCircleMinus size={25} />
				</button>
			</div>
			<h1 className="text-start font-extrabold w-full">
				{t(`${text}`)}
				<span className=" text-gray-800 font-extrabold text-[9px] block m-0">
					{t(hint)}
				</span>
			</h1>
		</div>
	);
}

export default PlusMinusRow;
